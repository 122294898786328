import React from 'react';

function Vr() {
  const [height, setHeight] = React.useState(0);
  const elementRef = React.useRef(null);

  React.useEffect(() => {
    const parent = elementRef.current.parentElement;
    setHeight(parent.offsetHeight);
  }, []);

  return (
    <hr className="vertical" style={{ height }} ref={elementRef} />
  );
}

export default Vr;
