import IdUtils from './IdUtils';

export function createBoard(name) {
  let boards = localStorage.getItem('boards');
  const boardId = IdUtils.generateId();
  if (boards == null) {
    boards = [];
  } else {
    boards = JSON.parse(boards);
  }
  const board = {
    id: boardId,
    name,
    columns: [{
      id: IdUtils.generateId(),
      content: `Todo ${name}`,
      showDone: false,
      tasks: [{
        id: IdUtils.generateId(),
        content: 'Task 1',
        done: false,
      }, {
        id: IdUtils.generateId(),
        content: 'Task 2',
        done: false,
      }],
    }, {
      id: IdUtils.generateId(),
      content: `In Progress ${name}`,
      showDone: false,
      tasks: [{
        id: IdUtils.generateId(),
        content: 'Task 3',
        done: false,
      }, {
        id: IdUtils.generateId(),
        content: 'Task 4',
        done: false,
      }],
    }, {
      id: IdUtils.generateId(),
      content: `Done ${name}`,
      showDone: true,
      tasks: [{
        id: IdUtils.generateId(),
        content: 'Task 5',
        done: true,
      }, {
        id: IdUtils.generateId(),
        content: 'Task 6',
        done: true,
      }],
    }],
  };
  boards.push(board);
  localStorage.setItem('boards', JSON.stringify(boards));
  return board;
}

export function joinBoard(id, email) {
  let userBoards = localStorage.getItem('userBoards');
  if (userBoards == null) {
    userBoards = [];
  } else {
    userBoards = JSON.parse(userBoards);
  }

  userBoards.push(
    {
      id,
      email,
    },
  );
  localStorage.setItem('userBoards', JSON.stringify(userBoards));
}
