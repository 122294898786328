import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

function Button({ onClick, children }) {
  return <button className="btn" onClick={onClick} type="button">{children}</button>;
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Button;
