import React from 'react';
import './TextInput.css';
import PropTypes from 'prop-types';

function TextInput({
  value,
  onChange,
  type,
  label,
  placeholder,
  button,
  buttonOnClick,
}) {
  const handleChange = (e) => {
    const val = e.target.value;
    onChange(val);
  };
  const id = Math.random().toString(36).substring(7);

  return (
    <div className="text-input-container">
      <label className="text-input-label" htmlFor={id}>{label}</label>
      {!button ? <input className="text-input" value={value} type={type} onChange={handleChange} id={id} placeholder={placeholder} />
        : (
          <div className="button-container">
            <input className="text-input" value={value} type={type} onChange={handleChange} id={id} placeholder={placeholder} />
            <button type="submit" className="input-button" onClick={buttonOnClick} disabled={!button}>OK</button>
          </div>
        )}
    </div>
  );
}

TextInput.defaultProps = {
  type: 'text',
  value: '',
  button: false,
  buttonOnClick: () => {
  },
  onChange: () => {
  },
};

TextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  button: PropTypes.bool,
  buttonOnClick: PropTypes.func,
};

export default TextInput;
