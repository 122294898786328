import React from 'react';
import './Sidebar.css';
import PropTypes from 'prop-types';
import { useLocalStorage, useUser } from '../../hooks/UserHooks';
import Button from '../Button/Button';
import TextInput from '../TextInput/TextInput';
import { createBoard, joinBoard } from '../../utils/boardUtils';

function Sidebar({ setBoard }) {
  const user = useUser();
  const [creatingNew, setCreatingNew] = React.useState(false);
  const [tabCode, setTabCode] = React.useState('');

  const [name, setName] = React.useState('');
  const [boardList, setBoardList] = useLocalStorage('boards', []);

  const [currentBoard, setCurrentBoard] = useLocalStorage('currentBoard', null);

  const createTab = () => {
    const board = createBoard(name);
    joinBoard(board.id, user.email);
    setName('');
    setCreatingNew(false);
    setBoardList([...boardList, board]);
    setBoard(board);
    setCurrentBoard(board.id);
  };

  const joinBoardWithId = () => {
    joinBoard(tabCode, user.email);
    setTabCode('');
  };

  return (
    <aside className="sidebar">
      {user ? (
        <>
          <div className="sidebar-content">
            <Button onClick={() => setCreatingNew(!creatingNew)} className="sidebar-topbutton">
              {creatingNew ? 'Annuler création' : 'Créer nouveau tableau'}
            </Button>
            <br />
            <br />

            {creatingNew ? (
              <div>
                <TextInput label="Name" type="text" placeholder="Entrez le nom" value={name} onChange={setName} />
                <Button onClick={createTab}>Créer tableau</Button>
              </div>
            ) : (
              <div>
                Liste des tableaux
                <br />
                <div className="sidebar-list-container">
                  <ul>
                    {boardList.map((board) => (
                      <button type="button" key={board.id} disabled={currentBoard === board.id} onClick={() => setCurrentBoard(board.id)}>{board.name}</button>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className="sidebar-footer">
            {(currentBoard !== '') && (
              <div>
                Code du tableau actuel
                <br />
                {currentBoard}
                <br />
                <br />
              </div>
            )}
            <TextInput buttonOnClick={joinBoardWithId} label="Rejoindre un tableau :" button type="text" placeholder="Entrez le code" value={tabCode} onChange={setTabCode} />
          </div>
        </>
      ) : (
        <div className="sidebar-content">
          <br />
          Connectez vous pour utiliser le site
        </div>
      )}
    </aside>
  );
}

Sidebar.propTypes = {
  setBoard: PropTypes.func.isRequired,
};

export default Sidebar;
