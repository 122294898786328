import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './BoardColumn.css';
import Button from '../../Button/Button';
import IdUtils from '../../../utils/IdUtils';

function BoardColumn({
  index,
  column: { content: title, tasks: quotes, showDone },
  maxIndex,
  reorderColumns,
  removeColumn,
}) {
  const [columnTitle, setColumnTitle] = useState(title);
  const [tasks, setTasks] = useState(quotes);
  const [hideDone, setHideDone] = useState(!showDone);
  const buttonRef = React.createRef();
  const columnMenuRef = React.createRef();
  const closeColumnMenu = () => {
    document.removeEventListener('click', closeColumnMenu);
    document.querySelectorAll('.board-column-menu').forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.style.display = 'none';
    });
  };
  const openColumnMenu = (e) => {
    closeColumnMenu();
    const el = columnMenuRef.current;
    el.style.display = 'flex';
    document.addEventListener('click', closeColumnMenu);
    const rect = buttonRef.current.getBoundingClientRect();
    el.style.top = `${rect.bottom}px`;
    el.style.left = `${rect.left}px`;
    e.stopPropagation();
  };
  const onRemoveColumn = () => {
    setTimeout(() => {
      removeColumn(index);
    }, 100);
  };
  const setDone = (e, id) => {
    const el = e.target;
    setTasks((prev) => prev.map((task) => {
      if (task.id === id) {
        return { ...task, done: el.checked };
      }
      return task;
    }));
  };
  const onTaskAdd = () => {
    setTasks((prev) => [...prev, { id: IdUtils.generateId(), content: 'Todo', done: false }]);
  };
  const onTaskContentChange = (e, id) => {
    const el = e.target;
    setTasks((prev) => prev.map((task) => {
      if (task.id === id) {
        return {
          ...task,
          content: el.innerText,
        };
      }
      return task;
    }).filter((task) => task.content.trim().length > 0));
  };
  const shouldShow = (task) => {
    if (!hideDone) {
      return true;
    }
    return !task.done;
  };
  const isFirst = (id) => tasks.filter(shouldShow).findIndex((task) => task.id === id) === 0;
  const isLast = (id) => tasks
    .filter(shouldShow).findIndex((task) => task.id === id) === tasks.filter(shouldShow).length - 1;
  const moveTask = (id, direction) => {
    const idx = tasks.findIndex((task) => task.id === id);
    if (idx === -1) {
      return;
    }
    const newIndex = idx + direction;
    if (newIndex < 0 || newIndex >= tasks.length) {
      return;
    }
    const newTasks = [...tasks];
    newTasks[idx] = tasks[newIndex];
    newTasks[newIndex] = tasks[idx];
    setTasks(newTasks);
  };

  return (
    <>
      <div className="board-column">
        <div className="board-column-header">
          <input value={columnTitle} onChange={(e) => setColumnTitle(e.target.value)} />
          <button type="button" onClick={openColumnMenu} aria-label="Open menu" ref={buttonRef}><i className="fas fa-ellipsis-v" /></button>
        </div>
        <div className="board-column-content">
          {tasks.filter(shouldShow).map((task) => (
            <div key={task.id} className={`board-quote ${task.done ? 'done' : ''}`}>
              <input type="checkbox" checked={task.done} onChange={(e) => setDone(e, task.id)} />
              <p contentEditable={!task.done} onBlur={(e) => onTaskContentChange(e, task.id)}>
                {task.content}
              </p>
              <div>
                {!isFirst(task.id) && <i className="fas fa-arrow-up" onClick={() => moveTask(task.id, -1)} tabIndex="0" role="button" aria-label="Move down" onKeyDown={() => moveTask(task.id, -1)} />}
                {!isLast(task.id) && <i className="fas fa-arrow-down" onClick={() => moveTask(task.id, 1)} tabIndex="0" role="button" aria-label="Move up" onKeyDown={() => moveTask(task.id, 1)} />}
              </div>
            </div>
          ))}
          <Button onClick={onTaskAdd}>Add task</Button>
        </div>
      </div>
      <div className="board-column-menu" ref={columnMenuRef}>
        <button type="button" onClick={onRemoveColumn}>
          Delete column
        </button>
        <button type="button" onClick={() => setHideDone((prev) => !prev)}>
          {hideDone ? 'Show done tasks' : 'Hide done tasks'}
        </button>
        {index !== 0 && (
        <button type="button" onClick={() => reorderColumns(index, -1)}>
          Move column to left
        </button>
        )}
        {index !== maxIndex && (
        <button type="button" onClick={() => reorderColumns(index, 1)}>
          Move column to right
        </button>
        )}
      </div>
    </>
  );
}

BoardColumn.propTypes = {
  column: PropTypes.shape({
    content: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
    showDone: PropTypes.bool.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  reorderColumns: PropTypes.func.isRequired,
  maxIndex: PropTypes.number.isRequired,
  removeColumn: PropTypes.func.isRequired,
};

export default BoardColumn;
