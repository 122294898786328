import './HomePage.css';
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import BoardContainer from '../../components/Board/BoardContainer/BoardContainer';

function HomePage() {
  const [board, setBoard] = useState({ columns: [] });

  useEffect(() => {
    const storedBoard = JSON.parse(localStorage.getItem('currentBoard'));
    const boardsFromStorage = JSON.parse(localStorage.getItem('boards'));
    let toSet;
    if (storedBoard) {
      toSet = boardsFromStorage.find((b) => b.id === storedBoard);
    }
    if (!toSet) {
      // array destructuring
      [toSet] = boardsFromStorage;
    }
    setBoard(toSet);
  }, []);

  return (
    <div className="Home-page">
      <Header />
      <Sidebar setBoard={setBoard} />
      <div className="board">
        <BoardContainer initial={board} />
      </div>
    </div>
  );
}

export default HomePage;
