import React, { useEffect } from 'react';
import './LoginPage.css';
import Button from '../../components/Button/Button';
import TextInput from '../../components/TextInput/TextInput';
import Container from '../../components/Container/Container';
import FlexBox from '../../components/FlexBox/FlexBox';
import Vr from '../../components/Vr/Vr';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import { useLocalStorage } from '../../hooks/UserHooks';

function LoginPage() {
  const [users, setUsers] = useLocalStorage('users', []);
  const [, setCurrentUser] = useLocalStorage('currentUser', null);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loginEmail, setLoginEmail] = React.useState('');
  const [loginPassword, setLoginPassword] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState(null);

  const handleLogin = () => {
    setErrorMsg(null);
    const user = users.find((u) => u.email === loginEmail && u.password === loginPassword);
    if (user) {
      setCurrentUser({ email: loginEmail });
      window.location.href = '/';
    } else {
      setErrorMsg('Invalid credentials');
    }
  };
  const handleRegister = () => {
    if (!name || !email || !password) {
      setErrorMsg('All fields are required');
      return;
    }
    const newUser = { name, email, password };
    if (users.find((u) => u.email === email) || users.find((u) => u.name === name)) {
      setErrorMsg('User already exists');
      return;
    }
    setUsers([...users, newUser]);
    setCurrentUser({ email });
    window.location.href = '/';
  };

  useEffect(() => {
    localStorage.setItem('users', JSON.stringify(users));
  }, [users]);

  return (
    <div className="login-page">
      <Container>
        <FlexBox row>
          <FlexBox>
            <h1>Register</h1>
            <TextInput label="Name" type="text" placeholder="Enter your name" value={name} onChange={setName} />
            <TextInput label="Email" type="email" placeholder="Enter your email" value={email} onChange={setEmail} />
            <TextInput label="Password" type="password" placeholder="Enter your password" value={password} onChange={setPassword} />
            <Button onClick={handleRegister}>Register</Button>
          </FlexBox>
          <Vr />
          <FlexBox>
            <h1>Login</h1>
            <TextInput label="Email" type="email" placeholder="Enter your email" value={loginEmail} onChange={setLoginEmail} />
            <TextInput label="Password" type="password" placeholder="Enter your password" value={loginPassword} onChange={setLoginPassword} />
            <Button onClick={handleLogin}>Login</Button>
          </FlexBox>
        </FlexBox>
        {errorMsg && <ErrorBox>{errorMsg}</ErrorBox>}
      </Container>
    </div>
  );
}

export default LoginPage;
