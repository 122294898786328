import React from 'react';
import './ErrorBox.css';
import PropTypes from 'prop-types';

function ErrorBox({ children }) {
  return <div className="error-box">{children}</div>;
}

ErrorBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBox;
