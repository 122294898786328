import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Header.css';
import { useUser } from '../../hooks/UserHooks';
import Button from '../Button/Button';

function Header() {
  const user = useUser();
  const navigate = useNavigate(); // Utilisez le hook useNavigate

  const handleLogin = () => {
    navigate('/login'); // Utilisez navigate pour la redirection
  };

  const handleLogout = () => {
    localStorage.removeItem('currentUser');
    window.location.reload();
  };

  return (
    <div className="header">
      {
        user ? (
          <>
            <div>
              <h1>YaMa</h1>
              <h3>
                Connected as
                {' '}
                {user.name}
              </h3>
            </div>
            <Button onClick={handleLogout}>Logout</Button>
          </>
        ) : (
          <>
            <div>
              <h1>YaMa</h1>
              <h3>
                You are not connected
              </h3>
            </div>
            <Button onClick={handleLogin}>Login</Button>
          </>
        )
      }
    </div>
  );
}

export default Header;
