import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BoardColumn from '../BoardColumn/BoardColumn';
import './BoardContainer.css';
import Button from '../../Button/Button';
import IdUtils from '../../../utils/IdUtils';

function BoardContainer({ initial = { columns: [] } }) {
  const [columns, setColumns] = useState(initial.columns);
  const [columnCount, setColumnCount] = useState(initial.columns ? initial.columns.length : 0);
  const addColumn = () => {
    const newKey = `Column ${columnCount + 1}`;
    setColumnCount((prev) => prev + 1);
    const newColumns = [...columns];
    newColumns.push({
      id: IdUtils.generateId(),
      content: newKey,
      showDone: false,
      tasks: [],
    });
    setColumns(newColumns);
  };
  const removeColumn = (index) => {
    setColumns((prev) => {
      const newColumns = [...prev];
      newColumns.splice(index, 1);
      return newColumns;
    });
  };
  const reorderColumns = (sourceIndex, direction) => {
    if (sourceIndex + direction < 0 || sourceIndex + direction >= columns.length) {
      return;
    }
    setColumns((prev) => {
      const newColumns = [...prev];
      const temp = newColumns[sourceIndex];
      newColumns[sourceIndex] = newColumns[sourceIndex + direction];
      newColumns[sourceIndex + direction] = temp;
      return newColumns;
    });
  };
  useEffect(() => {
    setColumns(initial.columns);
    setColumnCount(initial.columns ? initial.columns.length : 0);
  }, [initial]);

  return !initial.columns || !initial.columns.length ? <div /> : (
    <div className="board-container">
      {columns.map((column, index) => (
        <BoardColumn
          key={column.id}
          column={column}
          index={index}
          maxIndex={columns.length - 1}
          reorderColumns={reorderColumns}
          removeColumn={removeColumn}
        />
      ))}
      <div>
        <Button onClick={addColumn}>Add Column</Button>
      </div>
    </div>
  );
}

BoardContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initial: PropTypes.object.isRequired,
};

export default BoardContainer;
