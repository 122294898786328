import React from 'react';
import './Container.css';
import PropTypes from 'prop-types';

function Container({ children, full }) {
  let className = 'container';
  if (full) {
    className += ' container-full';
  }

  return <div className={className}>{children}</div>;
}

Container.defaultProps = {
  full: false,
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  full: PropTypes.bool,
};

export default Container;
