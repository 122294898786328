import useLocalStorage from './useLocalStorage';

function useUser() {
  const [users] = useLocalStorage('users', []);
  const [user] = useLocalStorage('currentUser', null);

  return users.find((u) => u.email === user.email);
}

export {
  useLocalStorage,
  useUser,
};
