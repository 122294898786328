import React from 'react';
import './ErrorPage.css';
import Button from '../../components/Button/Button';

function ErrorPage() {
  const handleRedirect = () => {
    window.location.href = '/';
  };

  return (
    <div className="error-page">
      <div className="error-container">
        <h1>404 - Page Not Found</h1>
        <p>Sorry, but the page you were trying to view does not exist.</p>
        <Button onClick={handleRedirect}>Go Home</Button>
      </div>
    </div>
  );
}

ErrorPage.propTypes = {};

export default ErrorPage;
