import React from 'react';
import './FlexBox.css';
import PropTypes from 'prop-types';

function FlexBox({
  children, row, justify, align,
}) {
  let className = 'flexbox';
  if (row) {
    className += ' flexbox-row';
  }
  if (justify) {
    className += ` flexbox-justify-${justify}`;
  }
  if (align) {
    className += ` flexbox-align-${align}`;
  }

  return <div className={className}>{children}</div>;
}

FlexBox.defaultProps = {
  row: false,
  justify: null,
  align: null,
};

FlexBox.propTypes = {
  children: PropTypes.node.isRequired,
  row: PropTypes.bool,
  justify: PropTypes.oneOf(['start', 'end', 'center', 'between', 'around']),
  align: PropTypes.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
};

export default FlexBox;
